function currancyFormat(e) {
    return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD"
    }).format(e)
}

function iframeURLChange(e, t) {
    var o = null,
        n = function () {
            var n = e.contentWindow.location.href;
            console.log("newHref ==> ", n), n !== o && (t(n), o = n, console.log("lastDispatched ==> ", o))
        },
        i = function () {
            setTimeout(n, 0)
        };

    function a() {
        e.contentWindow.removeEventListener("unload", i), e.contentWindow.addEventListener("unload", i)
    }
    e.addEventListener("load", function () {
        a(), n()
    }), a()
}

function getFileExtension(e) {
    const t = e.split(".");
    return t[1] = e.split(".").pop(), t
}

function S3Config() {
    var e = generateBucketName(),
        t = mainObj.IDENTITY_POOL_ID;
    return AWS.config.update({
        region: "us-east-1",
        credentials: new AWS.CognitoIdentityCredentials({
            IdentityPoolId: t
        })
    }), new AWS.S3({
        params: {
            Bucket: e
        }
    })
}

function formatDate(e) {
    var t = new Date(e),
        o = "" + (t.getMonth() + 1),
        n = "" + t.getDate(),
        i = t.getFullYear();
    return o.length < 2 && (o = "0" + o), n.length < 2 && (n = "0" + n), [i, o, n].join("-")
}

function generateServiceURL(e = mainObj.WADMIN_URL) {
    var t = e,
        o = "";
    return "stg-" == mainObj.env_type ? o = "staging-" : "dev-" == mainObj.env_type && (o = "development-"), t.replace("ENV", o)
}

function generateTrackURL(e = mainObj.WTRACK_URL) {
    var t = e,
        o = "";
    return "stg-" == mainObj.env_type ? o = "staging-" : "dev-" == mainObj.env_type && (o = "development-"), t.replace("ENV", o)
}

function generateBucketName(e = mainObj.BUCKETNAME) {
    var t = "production-";
    return "stg-" == mainObj.env_type ? t = "stage-" : "dev-" == mainObj.env_type && (t = "development-"), e.replace("ENV", t)
}

function generateAPIURL() {
    let e = mainObj.env_api_url;
    e = (e = e.replace("PARTNER", mainObj.defaultPartner)).replace("SERVICE", mainObj.defaultService);
    var t = "";
    return "" != mainObj.env_type && (t = mainObj.env_type), console.log("mainObj.env_type----", mainObj.env_type), e = e.replace("ENV", t), console.log("URL----", e), e
}

function amplifyHeader(e) {
    return {
        "Content-Type": "application/x-amz-json-1.1",
        "x-amz-target": "CUSTOM_AUTH" === e ? "AWSCognitoIdentityProviderService.InitiateAuth" : "AWSCognitoIdentityProviderService.RespondToAuthChallenge"
    }
}

function generateHeader() {
    let e = mainObj.env_type ? mainObj.env_type : "",
        t = "production";
    "stg-" == e ? t = "staging" : "dev-" == e && (t = "development");
    let o = getCookie("accessToken"),
        n = {
            "Content-Type": "application/json",
            authorizetoken: o || "a"
        };
    if (o) {
        n.authorizetoken = o, n.session_id = getCookie("sid");
        let e = getCookie("idToken"),
            i = {
                partner: userInfo.s,
                environment: t,
                idtoken: e
            };
        n.extraparams = JSON.stringify(i)
    }
    return n
}

function validateEmail(e) {
    return !!/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(e)
}

function setCookie(e, t) {
    var o = new Date;
    o.setMonth(o.getMonth() + 12);
    const n = mainObj.cookieDomain,
        i = (e = mainObj.env_type + "" + e) + "=" + t + ";expires=" + o + ";domain=" + n + ";path=/";
    document.cookie = i
}

function getCookie(e) {
    for (var t = (e = mainObj.env_type + "" + e) + "=", o = document.cookie.split(";"), n = 0; n < o.length; n++) {
        for (var i = o[n];
            " " == i.charAt(0);) i = i.substring(1);
        if (0 == i.indexOf(t)) return i.substring(t.length, i.length)
    }
    return ""
}

function deleteCookie(e) {
    const t = mainObj.cookieDomain;
    e = mainObj.env_type + "" + e, document.cookie = e + "=; domain=" + t + "; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;"
}

function removeuserCookieData() {
    deleteCookie("uid"), deleteCookie("bid"), deleteCookie("cid"), deleteCookie("refreshToken"), deleteCookie("accessToken"), deleteCookie("idToken"), deleteCookie("sid");
    // localStorage.removeItem("cartList");
}
function removeCartData() {
    localStorage.removeItem("cartList");
}
function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

function checkuserlogin() {
    // return !!(getCookie("idToken") && getCookie("accessToken") && getCookie("sid") && getCookie("uid") && getCookie("bid") && getCookie("cid"))
    let isValid = false;
    if(getCookie("idToken") && getCookie("accessToken") && getCookie("sid") && getCookie("uid") && getCookie("bid") && getCookie("cid")){
        let parsedToken = parseJwt(getCookie("accessToken"));
        let currentTimeStamp = Math.round(new Date().getTime()/1000);
        if (parsedToken && parsedToken.exp && currentTimeStamp < parsedToken.exp) {
            isValid = true;
        }
    }
    if(!isValid){
        removeuserCookieData();
    }
    return isValid;
}

function webSocket(e) {
    return new Promise(function (t, o) {
        let n = new WebSocket("wss://" + mainObj.env_type + "services-websocket.wsuite.com");
        n.onopen = function (t) {
            console.log("Sending to server"), n.send(JSON.stringify({
                action: "OpenConnection",
                task_token: "x",
                executionArn: e
            }))
        }, n.onmessage = function (e) {
            console.log(`[message] Data received from server: ${e.data}`), n.close();
            let finalData = (e && typeof e ==='object' && e.data) ? e.data : JSON.parse(e);
            t(finalData);
        }, n.onclose = function (e) {
            e.wasClean ? console.log(`[close] Connection closed cleanly, code=${e.code} reason=${e.reason}`) : console.log("[close] Connection died")
        }, n.onerror = function (e) {
            o(e), console.log(`[error] ${e.message}`)
        }
    })
}
var personalPhoto = $("#personalPhoto");

function removePhoto() {
    personalPhoto.css("display", "none"), personalPhoto.attr("src", ""), $("#defaultPhoto").css("display", "block"), $("#availPhoto").hide()
}

function readfichier(e) {
    if (window.FileReader) {
        var t = e.target.files[0],
            o = new FileReader;
        t && t.type.match("image.*") ? o.readAsDataURL(t) : (personalPhoto.css("display", "none"), personalPhoto.attr("src", "")), o.onloadend = function (e) {
            $("#defaultPhoto").css("display", "none"), personalPhoto.attr("src", o.result), $("#availPhoto").show(), personalPhoto.css("display", "block"), $("#photo-require-validation").css("display", "none")
        }
    }
}
personalPhoto.css("display", "none"), $("#upload-button").click(function () {
    return $("#my-custom-design-upload").trigger("click"), !1
}), $("#my-custom-design-upload").change(function (e) {
    readfichier(e)
}), $("#cardNumber").keypress(function () {
    $("#cardNumber").val(), $(this).val(function (e, t) {
        return t.replace(/\W/gi, "").replace(/(.{4})/g, "$1-")
    })
}), 
$("#id_4").datetimepicker({
    allowInputToggle: !0,
    showClose: !0,
    showClear: !0,
    showTodayButton: !0,
    format: "MM/DD/YYYY",
    minDate: new Date()
}), 
$("#id_card").datetimepicker({
    allowInputToggle: !0,
    showClose: !0,
    showClear: !0,
    showTodayButton: !0,
    format: "MM/YY"
}), jQuery("#carousel").owlCarousel({
    autoplay: !1,
    lazyLoad: !0,
    loop: !0,
    margin: 20,
    responsiveClass: !0,
    autoHeight: !0,
    autoplayTimeout: 7e3,
    smartSpeed: 800,
    nav: !0,
    navText: ["<i class='fa fa-long-arrow-left'></i>", "<i class='fa fa-long-arrow-right'></i>"],
    responsive: {
        0: {
            items: 1
        },
        600: {
            items: 2
        },
        1023: {
            items: 2
        },
        1366: {
            items: 3
        }
    }
}),
    function (e) {
        e("#ulList").is(":empty") && e("#removefiles").hide(), e("#dynamicSkillRate").hide(), skillsArr = ["Communication skills", "Writing", "Organizational Skills", "Problem Solving", "Planning", "Comp Skills + Typing", "Research", "Word + Office", "Customer Service", "Microsoft Excel", "Jboss Application Server", "Metadata Design", "Integration Architecture", "Distributed Computing", "Information Architecture", "Apache Kafka", "Web Services Security", "Salesforce Integration", "Cloud Computing", "Application Development", "Architecture", "Artificial Intelligence", "Cloud Computing", "HTML", "C++", "C Language", "PHP", "UX Design", "Python", "JavaScript", "Java", "Ruby", "Team Building", "Team work", "Leadership", "Collaboration", "Written Communication", "Oral Communication", "Active Listening", "Scheduling", "Goal Oriented", "Digital Communications", "Manage Remote Working Teams", "Multitasking", "Meeting Deadlines", "ICT", "APIs", "Application and Server Monitoring Tools", "Attention to Detail", "AutoCAD", "Azure", "Configure Database Software", "Configuration Management", "Critical Thinking", "Database Administration", "Deploying Applications", "Develop and Secure Network Structures", "Emerging Technologies", "File Systems", "Implement Backup and Recovery Plan", "Implementation", "Information Systems", "Interaction Design", "Interaction Flows", "Install, Maintain, and Merge Databases", "Integrated Technologies", "Integrating Security Protocols with Cloud Design", "Internet", "Optimization", "IT Soft Skills", "Logical Thinking", "Leadership", "Operating Systems", "Mobile Applications", "Migrating Existing Workloads into Cloud Systems", "Open Source Technology Integration", "Optimizing Website Performance", "Problem Solving", "Project Management", "Software Engineering", "Software Quality Assurance (QA)", "TensorFlow", "User-Centered Design", "UI / UX", "Visual Basic", "Visual FoxPro", "Web Development", "Web Design"], e.each(skillsArr, function (t) {
            e("#skillList").append(e("<option></option>").attr("value", skillsArr[t]).text(skillsArr[t]))
        }), e(".custome-dropdown").select2();
        var t = e(".custome-dropdown-skill").select2({
            tags: !0,
            width: "100%"
        });
        e("#stateLoading").hide(), e("#close-sidebar1").hide(), e("#close-sideview").hide(), e("#close-sidebar").hide(), e("#close-freelancer").hide(), countryDetails(), packageList(), e("#datetimepicker1").datetimepicker();
        var o = e(".owl-carousel");
        e(".customNextBtn").click(function () {
            o.trigger("next.owl.carousel")
        }), e(".customPreviousBtn").click(function () {
            o.trigger("prev.owl.carousel")
        }), e(window).scroll(function () {
            e(this).scrollTop() > 100 ? e("#scroll").fadeIn() : e("#scroll").fadeOut(), e(this).scrollTop() > 3200 ? e("#scroll").addClass("topbg") : e("#scroll").removeClass("topbg")
        }), e("#mySearch").keypress(function (e) {
            13 == e.which && serachPackage()
        }), e("#scroll").click(function () {
            return e("html, body").animate({
                scrollTop: 0
            }, 600), !1
        }), t.on("select2:select", function (e) {
            getSkill(e.params.data.text.split("/").join(" "))
        }), t.on("select2:unselect", function (e) {
            unSelectSkill(e.params.data.text.split("/").join(" "))
        }), toastr.options.positionClass = "toast-top-center", toastr.options.closeButton = !0, e(window).on("load", function () {
            e(".custom-scroll").mCustomScrollbar()
        })
    }(jQuery), AOS.init({
        duration: 1200,
        once: !0
    });
var _gaq = _gaq || [];
_gaq.push(["_setAccount", "UA-36251023-1"]), _gaq.push(["_setDomainName", "jqueryscript.net"]), _gaq.push(["_trackPageview"]),
    function () {
        var e = document.createElement("script");
        e.type = "text/javascript", e.async = !0, e.src = ("https:" == document.location.protocol ? "https://ssl" : "http://www") + ".google-analytics.com/ga.js";
        var t = document.getElementsByTagName("script")[0];
        t.parentNode.insertBefore(e, t)
    }(), $("html").on("dragover", function (e) {
        e.preventDefault(), e.stopPropagation()
    }), $("html").on("drop", function (e) {
        e.preventDefault(), e.stopPropagation()
    }), $("#drop_file_area").on("dragover", function () {
        return $(this).parent().addClass("drag_over"), !1
    }), $("#drop_file_area").on("dragleave", function () {
        return $(this).parent().removeClass("drag_over"), !1
    }), $("#drop_file_area").on("drop", function (e) {
        e.preventDefault(), $(this).removeClass("drag_over"), newFileList = Array.from(e.originalEvent.dataTransfer.files);
        var t = "";
        if (newFileList.length > 0) {
            for (var o = 0; o < newFileList.length; o++) t += "<li id='dropfile" + o + "'>" + newFileList[o].name + "<a href='javascript:void(0)' onclick = 'removeSingleFiles(" + o + ")'>Remove</a></li>";
            $("#ulList").append(t)
        }
        $("#ulList").is(":empty") || $("#removefiles").show()
    }), $("#upload-portfolio-button").click(function () {
        return $("#portfolio-files").trigger("click"), !1
    }), $("#portfolio-files").on("change", function () {
        var e = $("#portfolio-files");
        console.log("fp-------\x3e", e), newFileList2 = [], console.log("newFileList2-------\x3e", newFileList2), newFileList2 = Array.from(e[0].files), console.log("newFileList2-----", newFileList2);
        var t = newFileList2.length,
            o = "";
        if (t > 0) {
            $(".selectfiles").remove(), $("#portfolio-require-validation").hide();
            for (var n = 0; n < t; n++) {
                var i = newFileList2[n].name;
                newFileList2[n].size, newFileList2[n].type, o += "<li class='selectfiles' id='selectfile" + n + "'>" + i + "<a href='javascript:void(0)' onclick = 'removeSingleFiles2(" + n + ")' > Remove </a></li>"
            }
            $("#ulList").append(o)
        }
        $("#ulList").is(":empty") || $("#removefiles").show()
    });


$("#people-document-button").click(function () {
    return $("#upload-photo").trigger("click"), !1
}),
    $("#upload-photo").on("change", function () {
        var e = $("#upload-photo");
        console.log("fp-------\x3e", e), newFileList2 = [], console.log("newFileList2-------\x3e", newFileList2), newFileList2 = Array.from(e[0].files), console.log("newFileList2-----", newFileList2);
        var t = newFileList2.length,
            o = "";
        if (t > 0) {
            $(".selectfiles").remove(), $("#portfolio-require-validation").hide();
            for (var n = 0; n < t; n++) {
                var i = newFileList2[n].name;
                newFileList2[n].size, newFileList2[n].type, o += "<li class='selectfiles' id='selectfile" + n + "'>" + i + "<a href='javascript:void(0)' onclick = 'removeSingleFiles2(" + n + ")' > Remove </a></li>"
            }
            $("#ulList").append(o)
        }
        $("#ulList").is(":empty") || $("#removefiles").show()
    });

const $inp = $(".otp-input");
$inp.on({
    paste(e) {
        const t = e.originalEvent.clipboardData.getData("text").trim();
        if (!/\d{6}/.test(t)) return e.preventDefault();
        const o = [...t];
        $inp.val(e => o[e]).eq(5).focus()
    },
    input(e) {
        const t = $inp.index(this);
        this.value && $inp.eq(t + 1).focus()
    },
    keydown(e) {
        const t = $inp.index(this);
        !this.value && "Backspace" === e.key && t && $inp.eq(t - 1).focus()
    }
});


function hoursFormat(h) {
    h = h * 60;
    var hours = Math.floor(h / 60);
    var minutes = h % 60;
    if (minutes === 0) {
        if (hours > 1) {
            logFormat = hours + ' Hrs';
        } else {
            if (hours > 1) {
                logFormat = hours + ' Hrs ' + Math.floor(minutes) + ' Mins';
            } else {
                logFormat = hours + ' Hr ' + Math.floor(minutes) + ' Mins';
            }
        }
    } else {
        if (hours > 1) {
            logFormat = hours + ' Hrs ' + Math.floor(minutes) + ' Mins';
        } else {
            logFormat = hours + ' Hr ' + Math.floor(minutes) + ' Mins';
        }
    }
    return logFormat;
}

function addToCart(leadType, childId, isBuy = false) {
    const cartObj = { 'leadType': leadType, 'childId': childId, 'qty': 1 };
    let cartList = localStorage.getItem("cartList");
    if (cartList) {
        cartList = JSON.parse(cartList);
    } else {
        cartList = [];
    }
    const findedObj = cartList.find((clo) => clo.childId === childId);
    if (!findedObj) {
        cartList.push(cartObj);
        localStorage.setItem("cartList", JSON.stringify(cartList));
        toastr.success('Item added successfully!!');
    } else {
        toastr.warning('Item already added in your cart!!');
    }
    if (isBuy) {
        openSidebar1();
    }
    getCartItemCount()
}

function removeCartItem(id) {
    console.log('removeCartItem --->', id);
    let cartList = localStorage.getItem("cartList");
    cartList = JSON.parse(cartList);
    const itemIndex = cartList.findIndex((ci) => ci.childId === id);
    if (itemIndex >= 0) {
        cartList.splice(itemIndex, 1);
    }
    toastr.success('Item remove successfully!!');
    localStorage.setItem("cartList", JSON.stringify(cartList));
    console.log('cartList --->', cartList);
    updateCartList();
    getCartItemCount();
}

function getCartItemCount() {
    let cartList = localStorage.getItem("cartList");
    if (cartList) {
        cartList = JSON.parse(cartList);
    } else {
        cartList = [];
    }

    console.log('getCartItemCount -->', cartList.length);
    if (cartList.length) {
        $('#cart-item-count').html(cartList.length);
    } else {
        $('#cart-item-count').html('');
    }
}